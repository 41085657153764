import React, { useEffect, useRef } from 'react';
import bgservice from "../Images-Folder/vector-contact-us-background-removebg-preview-eqYmZMAm2-transformed.png";
import { Col, Container, Row } from 'react-bootstrap';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ContactsIcon from '@mui/icons-material/Contacts';
import MailIcon from '@mui/icons-material/Mail';
import "../PagesKalathiyaCss/ServicePage.css";
import "../PagesKalathiyaCss/ContactPage.css";

function ContactDetails() {
  const contactref = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in-up');
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.1
      }
    );

    contactref.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      contactref.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    await fetch('https://formspree.io/f/mldrgqer', {
      method: 'POST',
      headers: {
        'Accept': 'application/json'
      },
      body: formData
    });

    e.target.reset();
  };

  return (
    <div className=''>
      <div className="bg-image" ref={el => contactref.current.push(el)}>
        <Container>
          <Row className='align-items-center py-4 py-md-5 p-3'>
            <Col lg={6} className="text-center text-md-start  p-0 p-md-4">
              <div className="display-1 fw-bolder">Contact <span className="col-text">Us</span></div>
              <p className="pt-3 w-100 text-gray-500">
                Experience personalized service at Kalathiya Associates, where your financial journey begins. Visit us to explore tailored solutions in a welcoming environment.
              </p>
            </Col>
            <Col lg={6} className="text-center">
              <img src={bgservice} className="img-fluid m-auto pt-5" width={500} alt="Our Service" />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="container-fluid g-0">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="h1-bo text-uppercase pt-5 display-5 fw-bold">MAP</h2>
              <p className="pb-5 pt-3 w-100 text-sm-center f-size">Use the interactive map below to find us easily. Click on the map to get step-by-step directions from your location.
              </p>
            </div>
          </div>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3718.9307575574626!2d72.80855637471991!3d21.23459418067818!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04fb2ceeef58b%3A0x330ae60f6f2cc344!2sShivalik!5e0!3m2!1sen!2sin!4v1720172784989!5m2!1sen!2sin"
          width="600" height="450" allowFullScreen="" loading="lazy"
          referrerPolicy="no-referrer-when-downgrade" className="w-100" ref={el => contactref.current.push(el)}></iframe>
      </div>

      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-6">
            <div className="row">
              <div className="col-12">
                <div className="a rounded-md mb-4 text-center p-4" ref={el => contactref.current.push(el)}>
                  <LocationOnIcon className="fs-1 color rounded-circle bia p-2" />
                  <h5 className="mt-3 fw-bolder text-body-secondary pt-2">Our Address</h5>
                  <p className='text-gray-400'>405,SHIVALIK OPP.HENY ARCADE,DABHOLI GAM,KATARGAM,SURAT 395004</p>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="a rounded-md mb-4 text-center p-4" ref={el => contactref.current.push(el)}>
                  <MailIcon className="fs-1 color rounded-circle bia p-2" />
                  <h5 className="mt-3 fw-bolder text-body-secondary pt-2">Gmail Us</h5>
                  <p className='text-gray-400'>kalathiyaassociates.co@gmail.com</p>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 pb-5 pb-md-0 col-lg-6">
                <div className="a rounded-md text-center p-4" ref={el => contactref.current.push(el)}>
                  <ContactsIcon className="fs-1 color rounded-circle bia p-2" />
                  <h5 className="mt-3 fw-bolder text-body-secondary pt-2">Call Us</h5>
                  <p className='text-gray-400'>+91 98249 12752</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-6">
            <div className="gx-5 gy-4 gy-md-1 gy-lg-0">
              <div className="col-12">
                <form onSubmit={handleSubmit} className="row rounded-md a p-3" ref={(el) => contactref.current.push(el)}>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <input
                      type="text"
                      name="Name"
                      className="mt-3 w-100 rounded border border-1 p-2 uppercas"
                      placeholder="Your Full Name"
                      required
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 ">
                    <input
                      type="email"
                      name="Email"
                      className="mt-3 w-100 rounded border border-1 p-2"
                      placeholder="Your Email"
                      required
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <input
                      type="number" // Use text to manage length
                      name="contactNumber"
                      className="mt-3 w-100 rounded border border-1 p-2"
                      placeholder="Contact Number"
                      required
                      maxLength="10"
                      pattern="\d{10}"
                      title="Please enter a valid 10-digit phone number"
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <input
                      type="text"
                      name="Subject"
                      className="mt-3 w-100 rounded border border-1 p-2 uppercas"
                      placeholder="Subject (Ex.Home Loan)"
                      required
                    />
                  </div>
                  <div className="col-12">
                    <textarea
                      name="Message"
                      className="border p-2 border-1 mt-3 rounded w-100 uppercas"
                      placeholder="Message"
                      rows="6"
                      required
                    />
                  </div>
                  <button type="submit" className="col-auto mx-auto mt-4 rounded-2 ps-3 pe-3 mb-5 p-2 fs-5 coly">
                    Send Message
                  </button>
                </form>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default ContactDetails;
