import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import '../Navbar/NavbarMain.css';

function NavbarMain() {
  const toggleRef = useRef(null);

  const handleNavLinkClick = () => {
    if (toggleRef.current) {
      toggleRef.current.click();
    }
  };

  return (
    <>
      <Navbar expand="lg" className="navbar p-3 m-0 z-2">
        <Container className='navbarbg'>
          <Navbar.Toggle 
            aria-controls="basic-navbar-nav" 
            className='ms-auto border-none shadow-none' 
            ref={toggleRef} 
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="m-auto">
              <NavLink 
                to="/" 
                className="nav-link ms-5 fs-5 fw-medium text-blue-900"
                onClick={handleNavLinkClick}
              >
                Home
              </NavLink>
              <NavLink 
                to="/Aboutus" 
                className="nav-link fw-medium ms-5 fs-5 text-blue-900"
                onClick={handleNavLinkClick}
              >
                About Us
              </NavLink>
              <NavLink 
                to="/Service" 
                className="nav-link fw-medium ms-5 fs-5 text-blue-900"
                onClick={handleNavLinkClick}
              >
                Service
              </NavLink>
              <NavLink 
                to="/Contact" 
                className="nav-link fw-medium ms-5 fs-5 text-blue-900"
                onClick={handleNavLinkClick}
              >
                Contact
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarMain;
