import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './index.css';
import NavbarMain from './Navbar/NavbarMain';
import HomePage from './PagesKalathiya/HomePage';
import Aboutus from './PagesKalathiya/Aboutus';
import ContactDetails from './PagesKalathiya/ContactDetails';
import Nppage from './PagesKalathiya/Nppage';
import ServicePage from './PagesKalathiya/ServicePage';
import LogoPage from './PagesKalathiya/LogoPage';
import Footer from './Navbar/Footer';
import ScrollToTop from './Navbar/ScrollToTop';
import { Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import LoderPage from './LODERPAGE/LoderPage';


function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [showScrollButton, setShowScrollButton] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); 
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  if (isLoading) {
    return <LoderPage />;
  }


  return (
    <div className="App">
      {showScrollButton && (
        <Button
          style={{
            position: 'fixed',
            right: '20px',
            bottom: '50px',
            zIndex: 1000,
          }}
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          className='btnbgcolor bg-white '>
          <KeyboardDoubleArrowUpIcon />
        </Button>
      )}
      <BrowserRouter>
        <ScrollToTop />
        <LogoPage />
        <NavbarMain />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/Aboutus" element={<Aboutus />} />
          <Route path="/Contact" element={<ContactDetails />} />
          <Route path="/Service" element={<ServicePage />} />
          <Route path="*" element={<Nppage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
