import React, { useEffect, useRef } from 'react';
import bgservice from "../Images-Folder/Artboard-55.png-scaled-e1635494214965.avif";
import "../PagesKalathiyaCss/ServicePage.css";
import { Col, Container, Row } from 'react-bootstrap';

import MortgageLoan from "../Images-Folder/servicesmortgageloan.jpg"
import MortgageLoan2 from "../Images-Folder/loan-approval-scaled-1.jpg"
import HomeLoan from "../Images-Folder/servicehomeloan.png"
import HomeLoan2 from "../Images-Folder/home-loan-images-png-10-removebg-preview.png"
import Accounting from "../Images-Folder/accounting-matters-business-73.webp"
import Accounting2 from "../Images-Folder/GST-Accounting.jpg"
import Project from "../Images-Folder/servicesprojectloan.png"
import Project2 from "../Images-Folder/Project_Loan_for_MSME_Sector1-removebg-preview.png"
import Machinery from "../Images-Folder/textile_machinery_c-removebg-preview.png"
import Machinery2 from "../Images-Folder/Textile-Business-Loan-1.jpg"
import Solar from "../Images-Folder/Solar-Loans-in-Hawaii-1000x600.jpg"
import Solar2 from "../Images-Folder/solar2.jpg"

const services = [
  {
    id: "v-pills-home-loan",
    title: "House Loan",
    description: "Experience swift approvals and competitive rates with our tailored home loan solutions.",
    details: "Qui laudantium consequatur laborum sit qui ad sapiente dila parde sonata raqer a videna mareta paulona marka",
    img: HomeLoan,
    imgTwo: HomeLoan2,
    peratwo: "Our Home Loan Services is a gateway to realizing your dream of homeownership. We offer a range of mortgage options tailored to your needs, competitive interest rates, and expert guidance every step of the way. With a simple application process, personalized advice, and a commitment to making your journey smooth and transparent, we empower you to secure the home you've always wanted. Trust us to be your partner in making your homeownership dreams a reality.",
    listItems: [
      "New Home Purchase",
      "Understanding loan agreements",
      "Tailored loan options to fit individual needs",
      "Expert advice on choosing the right loan type",
      "Clear communication about costs, fees, and terms.",
      "Commitment to ethical practices and client-first approach.",
    ]
  },
  {
    id: "v-pills-mortgage-loan",
    title: "Project Loan",
    description: "Empower your projects with our loans—secure funding, competitive rates, and full support for your vision.",
    details: "Qui laudantium consequatur laborum sit qui ad sapiente dila parde sonata raqer a videna mareta paulona marka",
    img: Project,
    imgTwo: Project2,
    peratwo: "When embarking on ambitious projects, our project loans offer essential financial support. Whether it's for infrastructure development, construction, or large-scale renovations, we provide tailored financing solutions to suit your project's unique requirements. By leveraging the equity in your assets, our loans offer substantial funding with competitive interest rates and flexible repayment terms. This strategic approach enables you to execute your vision while retaining ownership and control over your project. It's more than just funding; it's the catalyst for transforming plans into reality with confidence and efficiency.",
    listItems: [
      "Ability to secure competitive interest rates and terms.",
      "Simplified documentation and application guidance.",
      "Efficient handling of approvals and disbursements.",
      "Comprehensive assessment of project feasibility and risks.",
      "Assistance with preparing business plans and financial projections.",
      "Clear communication of terms, costs, and obligations.",
    ]
  },
  {
    id: "v-pills-project-loan",
    title: "Mortgage Loan",
    description: "Secure your dream with our mortgage loans competitive rates, flexible terms, and personalized service.",
    details: "Qui laudantium consequatur laborum sit qui ad sapiente dila parde sonata raqer a videna mareta paulona marka",
    img: MortgageLoan,
    imgTwo: MortgageLoan2,
    peratwo: "For any kind of  personal fund requirements, the focus is on highlighting the benefits of obtaining a mortgage to meet financial needs. we emphasizes the opportunity to leverage the equity in one's home to secure a loan for various purposes, such as debt consolidation, home improvements, or investments, offering competitive interest rates and flexible terms. It assures potential borrowers that this strategic financial move can provide access to substantial funds while simultaneously allowing them to retain ownership of their property.",
    listItems: [
      "Expertise in securing favorable loan terms.",
      "Guidance through the mortgage application process.",
      "In-depth market knowledge and trend analysis.",
      "Clear explanation of fees, rates, and terms.",
      "Customized mortgage solutions to meet individual financial goals.",
      "Advice on choosing between fixed and adjustable-rate mortgages.",
    ]
  },
  {
    id: "v-pills-machinary-loan",
    title: "Machinery Loan",
    description: "Boost your business with our machinery loans competitive rates, flexible terms, and expert support.",
    details: "Qui laudantium consequatur laborum sit qui ad sapiente dila parde sonata raqer a videna mareta paulona marka",
    img: Machinery,
    imgTwo: Machinery2,
    peratwo: "For all your machinery financing needs, we emphasize the advantages of using a machinery loan to boost your business operations. Leverage the value of your equipment to secure funds for upgrades, expansion, or new acquisitions. Our competitive interest rates and flexible terms ensure you can access substantial capital while maintaining ownership of your assets. This strategic financial solution empowers your business to grow and thrive without sacrificing control over essential machinery.",
    listItems: [
      "Customized loan structures to match specific business needs.",
      "Expertise in negotiating the best loan conditions.",
      "Assistance with preparing required documentation.",
      "Guidance on choosing the right equipment financing options.",
      "Advice on refinancing and equipment upgrades.",
      "Clear communication about loan terms and costs.",
    ]
  },
  {
    id: "v-pills-Solar-loan",
    title: "Solar Loan",
    description: "Harness the power of the sun with our solar loans competitive rates, flexible terms, and sustainable savings.",
    details: "Qui laudantium consequatur laborum sit qui ad sapiente dila parde sonata raqer a videna mareta paulona marka",
    img: Solar,
    imgTwo: Solar2,
    peratwo: "When it comes to solar energy investments, our solar loans are designed to maximize your savings and environmental impact. Utilize the equity in your property to secure funding for solar panel installations, enabling you to reduce energy costs, enhance sustainability efforts, and increase property value. With competitive interest rates and flexible repayment options, our loans empower you to harness the sun's power while maintaining ownership of your home or business premises. It's a strategic financial choice that not only supports green initiatives but also secures long-term energy savings.",
    listItems: [
      "Tailored loan solutions to fit specific solar project needs.",
      "Access to a network of lenders offering favorable rates.",
      "Guidance through the loan application with minimal hassle.",
      "Support in maximizing tax incentives and rebates.",
      "Guidance on refinancing and system upgrades.",
      "Clear communication of all costs and terms.",
    ]
  },
  {
    id: "v-pills-accounting",
    title: "Accounting",
    description: "Streamline your finances with our accounting services expertise, precision, and strategic insights for business clarity.",
    details: "Qui laudantium consequatur laborum sit qui ad sapiente dila parde sonata raqer a videna mareta paulona marka",
    img: Accounting,
    imgTwo: Accounting2,
    peratwo: "When it comes to managing your finances, our accounting services are designed to streamline your operations and enhance financial clarity. From bookkeeping and tax preparation to financial analysis and advisory, we offer comprehensive solutions tailored to meet the specific needs of your business. Our team ensures accuracy, compliance, and strategic insights to empower informed decision-making and business growth. With a commitment to excellence and personalized service, we provide the expertise and support you need to navigate complex financial landscapes with confidence.",
    listItems: [
      "Custom financial strategies to enhance growth.",
      "Guidance on tax optimization and compliance.",
      "Simplification of financial management and planning.",
      "Ongoing financial analysis and advice.",
      "Assistance with budgeting, forecasting, and audits.",
      "Clear communication of all services and fees.",
    ]
  }
];

const ServicePage = () => {

  const sectionRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in-up');
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.1
      }
    );

    sectionRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      sectionRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  return (
    <>

      <div className="bg-image" ref={el => sectionRefs.current.push(el)}>
        <Container>
          <Row className='align-items-center py-4 py-md-5 p-3'>
            <Col lg={6} className="text-center text-md-start p-4">
              <div className="display-1 fw-bolder">Our <span className="col-text">Service</span></div>
              <p className="pt-3 w-100">
                Kalathiya Associates empowers your financial journey with personalized loans, strategic advice, and complete accounting solutions.
              </p>
            </Col>
            <Col lg={6} className="text-center">
              <img src={bgservice} className="img-fluid m-auto pt-5" width={500} alt="Our Service" />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="container-fluid bg-light bg-gradient mt-4">
        <Container className="text-center py-4 py-md-5">
          <div className='display-6 fw-bolder color' ref={el => sectionRefs.current.push(el)}>
            What <span className='text-black'>We</span> Provide?
          </div>
          <div className='fs-6 w-100 w-md-75 m-auto mt-3 text-style-2' ref={el => sectionRefs.current.push(el)}>
            Welcome to Kalathiya Associates – Your one-stop destination for comprehensive financial solutions. We specialize in offering a wide range of loans tailored to meet your specific needs, expert financial advisory services to guide you through every decision, and top-notch accounting services to keep your finances in perfect order. Our mission is to fulfill all your financial requirements with professionalism and integrity. Partner with us for a brighter financial future.
          </div>
        </Container>
      </div>



      <div className="container-fluid serviceopt">
        <div className="container">
          <div className="row text-center mt-5">
            <div className="col">
              <h2 className="mb-3 fw-bolder color fs-1">Our Services</h2>
              <p className='w-100 w-md-75 m-auto text-style-2 pb-3'>
                At Kalathiya Associates, our mission is to accelerate your financial growth by providing fast loan approvals, exceptional service, and personalized financial strategies to meet all your needs.
              </p>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-4 col-md-12">
              <div className="color">
                <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  {services.map((service, index) => (
                    <button
                      key={index}
                      className={`main ${index === 0 ? 'active' : ''} rounded-0 mt-2 text-start w-100`} // Added w-100 for full width
                      id={`v-pills-${service.id}-tab`}
                      data-bs-toggle="pill"
                      data-bs-target={`#${service.id}`}
                      type="button"
                      role="tab"
                      aria-controls={service.id}
                      aria-selected={index === 0}
                    >
                      <h6 className="fw-bolder">{service.title}</h6>
                      <p className='text-style-3'>{service.description}</p>
                    </button>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-lg-8 col-md-12">
              <div className="tab-content" id="v-pills-tabContent">
                {services.map((service, index) => (
                  <div
                    key={index}
                    className={`tab-pane fade ${index === 0 ? 'show active' : ''}`}
                    id={service.id}
                    role="tabpanel"
                    aria-labelledby={`v-pills-${service.id}-tab`}
                    tabIndex="0"
                    ref={el => sectionRefs.current.push(el)}
                  >
                    <h3 className="pt-2 fw-bolder fs-2 color">{service.title}</h3>
                    <p className="pt-3 text-style-2">{service.description}</p>
                    <div className="row flex justify-between pt-2">
                      <div className="col-lg-5 order-xs-1 order-sm-1 order-md-1 order-lg-1 pt-3">
                        <img src={service.img} className="" width={"100%"} alt={`${service.title} Bank`} />
                      </div>

                      <div className='pt-4 text-style-2 order-xs-2 order-sm-2 order-md-2 order-lg-3 text-md-center text-lg-start'>
                        {service.peratwo}
                      </div>

                      <div className="col-lg-5 order-xs-3 order-sm-3 order-md-3 order-lg-2 pt-3">
                        <img src={service.imgTwo} className="" width={"100%"} alt={`${service.title} Service`} />
                      </div>

                      <ul className="two-column-list pt-2 ps-2 ms-1 text-style-2 order-xs-4 order-sm-4 order-md-4 order-lg-4">
                        {service.listItems.map((item, idx) => (
                          <li key={idx}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default ServicePage;
