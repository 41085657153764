import React from 'react'
import logo from "../Images-Folder/kalathiya_co-removebg.jpg";
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { FaChevronRight } from 'react-icons/fa';
import "../Navbar/Footer.css";
import { Link } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import LocationOnIcon from '@mui/icons-material/LocationOn';
function Footer() {
    return (
        <>
            <div className="container-fluid peracol p-1">
                <Container className="mt-4 mb-5">
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={3} className="">
                            <img src={logo} width={150} alt="" className="d-block mx-auto mx-lg-0"/>
                            <p className="pt-3 pb-3 color fw-medium d-flex">
                             <LocationOnIcon /> <div>405,SHIVALIK OPP.HENY ARCADE,DABHOLI GAM,KATARGAM,SURAT 395004</div>
                            </p>
                            <p>
                                <a href="mailto:kalathiayaassociates.co@gmail.com" className="text-decoration-none fw-medium d-flex text-reset">
                                    <EmailIcon className='color' /> <p className='color'>kalathiayaassociates.co@gmail.com</p>
                                </a>
                                <br />
                                <a href="tel:+919824912752" className="text-decoration-none color fw-medium">
                                    <CallIcon /> +91 9824912752
                                </a>
                            </p>

                        </Col>

                        <Col xs={12} sm={12} md={6} lg={2} className="p-3">
                            <h5 className="fw-bold ms-3 fs-4 text-secondary">Useful Links</h5>
                            <ul className="ul">
                                <li className="pt-2 hovtext d-flex align-items-center">
                                    <FaChevronRight className="iconcol me-2" />
                                    <Link to="/" className="footertext color fw-medium">Home</Link>
                                </li>
                                <li className="pt-2 hovtext d-flex align-items-center">
                                    <FaChevronRight className="iconcol me-2" />
                                    <Link to="/Aboutus" className="footertext color fw-medium">About Us</Link>
                                </li>
                                <li className="pt-2 hovtext d-flex align-items-center">
                                    <FaChevronRight className="iconcol me-2" />
                                    <Link to="/service" className="footertext color fw-medium">Services</Link>
                                </li>
                                <li className="pt-2 hovtext d-flex align-items-center">
                                    <FaChevronRight className="iconcol me-2" />
                                    <Link to="/contact" className="footertext color fw-medium">Contact</Link>
                                </li>
                            </ul>
                        </Col>

                        <Col xs={12} sm={12} md={6} lg={3} className="p-3">
                            <h5 className="fw-bold ms-3 fs-4 text-secondary">Our Services</h5>
                            <ul className="ul">
                                <li className="pt-2 hovtext d-flex align-items-center">
                                    <FaChevronRight className="iconcol me-2" />  
                                          <Link to="/service" className="footertext color fw-medium">House Loan</Link>
                                </li>
                                <li className="pt-2 hovtext d-flex align-items-center">
                                    <FaChevronRight className="iconcol me-2" />  
                                          <Link to="/service" className="footertext color fw-medium">Project Loan</Link>
                                </li>
                                <li className="pt-2 hovtext d-flex align-items-center">
                                    <FaChevronRight className="iconcol me-2" /> 
                                           <Link to="/service" className="footertext color fw-medium">Mortgage Loan</Link>
                                </li>
                                <li className="pt-2 hovtext d-flex align-items-center">
                                    <FaChevronRight className="iconcol me-2" /> 
                                          <Link to="/service" className="footertext color fw-medium">Machinery Loan</Link>
                                </li>
                                <li className="pt-2 hovtext d-flex align-items-center">
                                    <FaChevronRight className="iconcol me-2" /> 
                                          <Link to="/service" className="footertext color fw-medium">Solar Loan</Link>
                                </li>
                            </ul>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={4} className="p-3 text-start">
                            <h5 className="fw-bold fs-4 text-secondary">Our Newsletter</h5>
                            <p className='mt-3 color fw-medium'>Join our newsletter for expert financial tips, insights, and exclusive updates. Enhance your financial journey with us today!</p>
                            <InputGroup>
                                <Form.Control type="email" size="35" className="border border-1 p-2 shadow-none rounded-start mt-3" placeholder="Your Email" />
                                <Button className="p-2 bt mt-3 rounded-end">Subscribe</Button>
                            </InputGroup>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="text-center footersub p-2">
                <p>&copy; 2023 Your Company. All rights reserved.</p>
            </div>
        </>

    )
}

export default Footer