import React, { useEffect, useRef } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import aboutuspage from "../Images-Folder/about us photo.png";
import aboutus from "../Images-Folder/2672335-removebg-preview.png";
import "../PagesKalathiyaCss/AboutusPage.css";

function Aboutus() {
  const missionPoints = [
    "Promote sustainable financial practices that benefit both clients and the community.",
    "Keep clients informed and engaged with regular updates and personalized advice.",
    "Develop customized financial plans to meet the unique needs of each client.",
    "Actively seek and incorporate client feedback to enhance service quality",
    "Maintain the highest standards of integrity and transparency in all client dealings.",
  ];

  const visionPoints = [
    "Deliver tailored financial strategies that align with each client's unique goals.",
    "Continuously innovate to offer cutting-edge financial solutions.",
    "Build lasting relationships based on trust, clarity, and integrity.",
    "Make a positive impact on communities by promoting sustainable financial practices.",
    "Provide a smooth, user-friendly experience through every interaction.",
  ];

  const AboutRefs = useRef([]);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in-up');
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.1
      }
    );

    AboutRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      AboutRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  return (
    <>
      <div className="bg-image" ref={el => { if (el) AboutRefs.current[0] = el }}>
        <Container>
          <Row className='align-items-center p-3 p-md-5'>
            <Col lg={6} ref={el => { if (el) AboutRefs.current[1] = el }}>
              <div className="display-1 fw-bolder text-center text-md-start">About<span className="col-text color"> Us</span></div>
              <p className="pt-3 w-100 text-center text-md-start f-size">
                Find dependable financial advice at Kalathiya Associates & Co, where experience and customized tactics combine to produce observable, long-lasting effects.
              </p>
            </Col>
            <Col lg={6} ref={el => { if (el) AboutRefs.current[2] = el }}>
              <img src={aboutus} className="img-fluid m-auto fade-in-up" alt="Our Service" />
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="pb-3 pb-md-5">
        <Card className="mb-3" style={{ border: 'none' }}>
          <Row className="text-center">
            <Col>
              <div className="display-6 h1-bo fw-bolder text-center">What<span className="col-text color"> We </span>Offer !</div>
              <p className="pb-2 pb-md-4 w-100 w-md-75 m-auto f-size">
                Discover comprehensive financial solutions at Kalathiya Associates & Co. From loans and advisory services to precise accounting, we empower your financial success with personalized strategies and dedicated support.
              </p>
            </Col>
          </Row>
          <Row className="g-0 g-2 row-cols-1 row-cols-lg-2 mt-2 p-3 ps-3 ps-md-5 pe-3 pe-md-5">
            <Col className="order-lg-1 order-1 pe-2">
              <img src={aboutuspage} className="img-fluid rounded" alt="About Us" ref={el => { if (el) AboutRefs.current[3] = el }} />
            </Col>
            <Col className="order-lg-2 order-2 mb-3">
              <Card.Body>
                <h5 className="card-title size-blue">Empowering Financial Futures with Expertise and Trust.</h5>
                <p className="card-text size-we">
                  Personalized solutions for every financial goal.
                </p>
                <p className='pt-3 size-f'><CheckCircleOutlineIcon className="me-2 bie fs-4" />Loans, accounting, and personalized financial guidance.</p>
                <p className='pt-3 size-f'><CheckCircleOutlineIcon className="me-2 bie fs-4" />Tailored financial solutions backed by industry expertise.</p>
                <p className='pt-3 size-f'><CheckCircleOutlineIcon className="me-2 bie fs-4" />Understanding your needs, delivering trusted financial advice.</p>
                <p className='mt-3 size-we'>
                  Established 2011, our financial advisory firm has grown to become a trusted partner in navigating the complexities of finance. We specialize in offering a wide range of loans, including mortgage loans, personal loans, business loans, and vehicle loans, designed to empower our clients with financial freedom and flexibility.
                </p>
              </Card.Body>
            </Col>
          </Row>
        </Card>
      </Container>

      <Container>
        <div>
          <div className="area">
            <h1 className='display-6 color fw-bold text-center mb-4'>Mission and Vision</h1>
            <div className="context">
              <Row className="d-flex p-3 p-md-5">
                <Col xs={12} md={6}>
                  <div className="area">
                    <div className="context">
                      <ul>
                        {missionPoints.map((point, index) => (
                          <li key={index} className='ms-3 ms-md-5'>
                            <p className='w-100 p-2'>{point}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="area">
                    <div className="context">
                      <ul>
                        {visionPoints.map((point, index) => (
                          <li key={index} className='ms-3 ms-md-5'>
                            <p className='w-100 p-2'>{point}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <ul className="symbols">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Aboutus;
