import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import logo from "../Images-Folder/kalathiya_co-removebg.jpg";
import { Container, Nav, Navbar } from 'react-bootstrap';
import '../PagesKalathiyaCss/Logopage.css';

function LogoPage() {
    const location = useLocation();

    return (
        <>
            <Navbar expand="lg" className="bgcolor navbar p-0 m-0 ps-5 pe-5 z-1">
                <Container className="p-2 d-flex justify-content-between align-items-center flex-column flex-lg-row text-center text-lg-start">
                    <div>
                        <a href="mailto:kalathiayaassociates.co@gmail.com" className="text-decoration-none bgcolor text-reset">
                            kalathiayaassociates.co@gmail.com
                        </a>
                    </div>
                    <div>
                        <a href="tel:+919824912752" className="text-decoration-none text-white">
                            +91 9824912752
                        </a>
                    </div>
                </Container>
            </Navbar>

            <div>
                <Link to="/">
                    <img src={logo} key={location.pathname} className='m-auto p-5 img-animation' width={600} alt="Logo" />
                </Link>
            </div>
        </>
    );
}

export default LogoPage;
