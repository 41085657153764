import React, { useEffect, useRef, useState } from 'react';
import "../PagesKalathiyaCss/HomePage.css";

import bgground from "../Images-Folder/home-loan-1.png";
import { Button, Col, Container, Row } from 'react-bootstrap';
import { motion } from 'framer-motion';

import MortgageLoan from "../Images-Folder/6MORGAGE LOAN.png";
import HomeLoan from "../Images-Folder/4HOMELOAN.png";
import Accounting from "../Images-Folder/1AC.png";
import Project from "../Images-Folder/5PROJECT-LOAN.png";
import Machinery from "../Images-Folder/2TEXTIL.png";
import Solar from "../Images-Folder/3SOLAR.png";

import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import HandshakeIcon from '@mui/icons-material/Handshake';

import axisbank from '../Images-Folder/axisbank-1.png';
import SBIBANK from '../Images-Folder/SBIBANK-2-removebg-preview.png';
import KOTAKBANK from '../Images-Folder/KOTAKBANK-3-removebg-preview.png';
import HDFCBANK from '../Images-Folder/HDFCBANK-4-removebg-preview.png';
import BOBBANK from '../Images-Folder/BOBBANK-5-removebg-preview.png';
import BOIBANK from '../Images-Folder/BOIBANK-6-removebg-preview.png';
import ICICIBANK from '../Images-Folder/ICICIBANK-7-removebg-preview.png';
import IDFCBANK from '../Images-Folder/IDFCBANK-17-removebg-preview.png';
import PNBBANK from '../Images-Folder/PNBBANK-9-removebg-preview.png';
import YESBANK from '../Images-Folder/YESBANK-10-removebg-preview.png';
import CANARABANK from '../Images-Folder/kanara_bank-removebg-preview-18.png';
import DBS from '../Images-Folder/AND-MANY-MORE-4.png';
import { Link } from 'react-router-dom';



function HomePage() {


  const animationVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: custom => ({
      opacity: 1,
      y: 0,
      transition: { delay: custom, duration: 0.3 },
    }),
  };

  const homeref = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in-up');
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.1
      }
    );

    homeref.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      homeref.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, [homeref.current]);




  const clientImages = [
    HDFCBANK, ICICIBANK, SBIBANK, KOTAKBANK, axisbank,
    PNBBANK, BOBBANK, CANARABANK, BOIBANK, YESBANK, IDFCBANK, DBS
  ];

  return (
    <div className='maindiv'>
      <div className="container-fluid bg-img g-0">
        <Container className="pb-0 pb-md-5">
          <Row className="pt-2 pb-0 pb-md-5">
            <Col xs={12} sm={12} lg={6} className="order-2 order-lg-1">
              <div className="pera">
                <div id="child" className="img-animation">
                  <img src={bgground} className='imghome img-fluid' alt="" />
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} lg={6} className="order-1 order-lg-2 pera mt-3 mt-md-0 text-center text-lg-start">
              <h2 className='mt-10 fw-bold'>Financial Advisor</h2>
              <div className="display-3 fw-bolder">
                Start <span className="col-text">Connecting</span> With Us For Financial Needs
              </div>
              <p className="pt-3 w-100 f-size">
                Empowering Your Financial Future with Expert Guidance.
              </p>
              <Link className="btn-all fw-bold mt-4 p-2 rounded text-white" to="/Aboutus" type="submit">About Us</Link>
            </Col>
          </Row>
        </Container>

      </div>

      <div className='section'>
        <Container className=''>
          <div className='fs-1 color fw-bolder'>Welcome To The Kalathiya Associates & Co.</div>
          <div className='fs-6 w-100 w-md-75 m-auto p-1 p-md-0 mt-3 text-style'>We provide a full range of financial services at <b className='underline'>Kalathiya Associates & Co</b> to satisfy all of your needs. Our committed staff is available to assist you with whatever needs you may have, including loans, precise accounting services, and professional financial guidance. We take great satisfaction in providing tailored solutions that make use of in-depth analysis to guarantee that your financial objectives are successfully and profitably realized. Put your trust in us to handle the financial complexity so you can concentrate on what really matters: your future. </div>
        </Container>
      </div>

      <div className="business-verticals">
        <motion.h1
          className='h1-sec-3'
          initial="hidden"
          animate="visible"
        >
          Business Verticals
        </motion.h1>

        <Container>
          <Row className="g-4 pb-5">
            {[
              { img: Machinery, title: 'Machinery Loan', text: 'Elevate your business capabilities with our machinery loans designed to fuel growth, enhance efficiency, and drive your success forward.', delay: 0 },
              { img: Solar, title: 'Solar Loan', text: 'Unlock savings from day one with our transformative solar loans empowering you to harness clean energy and cut costs effortlessly', delay: 0.1 },
              { img: Project, title: 'Project Loan', text: 'Fuel your business growth with our flexible project financing solutions, tailored to fund your ambitions and drive success.', delay: 0.2 },
              { img: HomeLoan, title: 'House Loan', text: 'Discover the key to your dream home with our tailored home loan solutions, designed to make your  journey seamless and rewarding.', delay: 0.3 },
              { img: MortgageLoan, title: 'Mortgage Loan', text: 'Secure your future with our flexible mortgage loan options, personalized to help you achieve homeownership with confidence.', delay: 0.4 },
              { img: Accounting, title: 'Accounting', text: 'Empower your business with precise accounting solutions that ensure clarity and strategic financial management.', delay: 0.5 },
            ].map((item, index) => (
              <Col lg={4} md={6} sm={12} xs={12} className="vertical" ref={el => { if (el) homeref.current[index] = el }} key={index}>
                <Link to="/Service">
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    variants={animationVariants}
                    custom={item.delay}
                  >
                    <div className="verticalsub p-4 pb-5">
                      <img src={item.img} className="m-auto p-5" alt={item.title} />
                      <h2 className="h2-sec-3 fs-4">{item.title}</h2>
                      <p className="p">{item.text}</p>
                    </div>
                  </motion.div>
                </Link>
              </Col>
            ))}
          </Row>
        </Container>

      </div>

      <Container className="text-center my-5">
        <Row>
          <Col xs={12} sm={12} md={4} lg={4} className="mb-4">
            <BookmarkIcon className="iconcolor mb-2" />
            <h3 className='fs-1 ms-3 fw-bold colorofother'>{13}+</h3>
            <p className='fw-bold fs-5 mt-2 colorofother'>Years of Experience</p>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} className="mb-4">
            <EmojiEventsIcon size={50} className="iconcolor mb-2" />
            <h3 className='fs-1 ms-3 fw-bold colorofother'>{3}+</h3>
            <p className='fw-bold fs-5 mt-2 colorofother'>Awards</p>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} className="mb-4">
            <HandshakeIcon size={50} className="iconcolor mb-2" />
            <h3 className='fs-1 ms-3 fw-bold colorofother'>100+</h3>
            <p className='fw-bold fs-5 mt-2 colorofother'>Working Projects</p>
          </Col>
        </Row>
      </Container>


      <div className="container-fluid bg-scal" id="Clients">
        <Container className="p-5">
          <Row>
            <Col className="pb-3 text-center">
              <h5 className="h6-hedding">Who we work with</h5>
              <h1 className="fw-bolder fs-1 mt-1 color">Bank Showcase</h1>
            </Col>
          </Row>
          <Row className="g-0 gy-4 gx-4 d-flex justify-center items-center custom-padding">
            {clientImages.map((client, index) => (
              <Col className='d-flex justify-center' key={index} xs={12} sm={12} md={6} lg={4} xl={3}>
                <div className="shadow scal p-5 rounded-4 d-flex justify-content-center align-items-center">
                  <img src={client} className="img-fluid p-4" alt={`Client ${index + 1}`} />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>


    </div>
  );
}

export default HomePage;
